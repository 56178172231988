.drag-left {
    transform: translateX(-100%);
    transition: .3s;
}

.drag-right {
    transform: translateX(100%);
    transition: .3s;
}

.drag-top {
    transform: translateY(-100%);
    transition: .3s;
}

.drag-bottom {
    transform: translateY(100%);
    transition: .3s;
}

.no-transition {
    transition: none;
}