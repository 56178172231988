.torrentFilms__edit-container {
    position: absolute;
    left: calc(100% - 5px);
    display: flex;
    background-color: white;
    padding: 5px;
    box-shadow: 0 7px 28px rgb(0 0 0 / 19%), 0 7px 10px rgb(0 0 0 / 22%);
    display: none;

}

.torrentFilms__edit-remove:hover .torrentFilms__edit-container {
    display: flex;
}

.torrentFilms__edit-button {
    padding: 5px;
}

.torrentFilms__edit-button:hover {
    opacity: .5;
}