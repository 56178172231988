@font-face {
  font-family: "Diablo";
  src: url("fonts/DiabloCyr.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin'), local('Roboto-Thin'), url('fonts/Robotothin.woff2') format('woff2'), url('fonts/Robotothin.woff') format('woff'), url('fonts/Robotothin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('fonts/Robotothinitalic.woff2') format('woff2'), url('fonts/Robotothinitalic.woff') format('woff'), url('fonts/Robotothinitalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/Robotolight.woff2') format('woff2'), url('fonts/Robotolight.woff') format('woff'), url('fonts/Robotolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('fonts/Robotolightitalic.woff2') format('woff2'), url('fonts/Robotolightitalic.woff') format('woff'), url('fonts/Robotolightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/Roboto.woff2') format('woff2'), url('fonts/Roboto.woff') format('woff'), url('fonts/Roboto.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Italic'), local('Roboto-Italic'), url('fonts/Robotoitalic.woff2') format('woff2'), url('fonts/Robotoitalic.woff') format('woff'), url('fonts/Robotoitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/Robotomedium.woff2') format('woff2'), url('fonts/Robotomedium.woff') format('woff'), url('fonts/Robotomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('fonts/Robotomediumitalic.woff2') format('woff2'), url('fonts/Robotomediumitalic.woff') format('woff'), url('fonts/Robotomediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/Robotobold.woff2') format('woff2'), url('fonts/Robotobold.woff') format('woff'), url('fonts/Robotobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('fonts/Robotobolditalic.woff2') format('woff2'), url('fonts/Robotobolditalic.woff') format('woff'), url('fonts/Robotobolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black'), local('Roboto-Black'), url('fonts/Robotoblack.woff2') format('woff2'), url('fonts/Robotoblack.woff') format('woff'), url('fonts/Robotoblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url('fonts/Robotoblackitalic.woff2') format('woff2'), url('fonts/Robotoblackitalic.woff') format('woff'), url('fonts/Robotoblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: Roboto;

  background-color: #EEEEEE;
  max-width: 100vw;

}

#root {
  max-width: 100vw;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  padding: 0;
}

p {
  margin: 0;
}

.content-wrapper {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #00000061;


}


.goods-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 20px;
}

.goods-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;
  padding: 20px;
  background-color: cornsilk;
}

.cart {
  width: 800px;
  margin-top: 50px;

}

.cart thead td {
  font-weight: bold;
  padding: 20px 10px;
}

.cart td {
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
  margin: 0px;
  padding: 10px;
  text-align: center;
}

.cart td:last-child {
  border-right: none;
}

.cart img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.cart td button {
  margin: 0 5px;
}

.total {
  margin-top: 30px;
  font-weight: bold;
}

.hidden {
  display: none !important;
}

.runes-field {
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 6px;
  justify-content: center;
}

.rune-block {
  width: 76px;
  padding: 5px;
  height: 66px;
  background-color: #30303085;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25), 0 3px 3px rgba(0, 0, 0, 0.22);
  cursor: pointer;

  opacity: .8;
}

.rune-block:hover {
  box-shadow: inset 2px 2px 5px rgba(154, 147, 140, 0.5), 1px 1px 5px rgb(0, 0, 0);
}

.rune-block.active {
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 1);
  background-color: #303030;
  opacity: 1;
}

.rune-block img {
  width: 35px;
  height: 35px;
}

.rune-name-en {
  margin-top: 0px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #8da59d;
  margin-left: 5px;
  font-size: 11px;
}

.rune-name-ru {
  margin: 0px;
  font-weight: bold;
  color: #eedb35;
  font-size: 11px;
}

.rune-block-wrapper {
  position: relative;
}

.rune-info {
  position: absolute;
  display: none;
  z-index: 2;
  top: calc(100% + 5px);
  left: 50%;
  border-radius: 2px;
  padding: 4px;
  background: #EEEEEE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  pointer-events: none;
  transform: translate(-50%, 0);
  column-gap: 4px;
  align-items: center;
  z-index: 5;
  flex-wrap: wrap;
  justify-content: center;

}

.rune-info:before {
  content: "";
  height: 15px;
  width: 15px;
  background: #EEEEEE;
  transform: rotate(45deg) translate(-50%, 0);
  bottom: calc(100% - 15px);
  left: 50%;
  position: absolute;
  z-index: -1;


}

.rune-info p {
  white-space: nowrap;
}

.rune-recept-pic {
  margin: 0;
}

.rune-info.left {
  left: auto;
  right: calc(100% + 5px);
  background-color: rgb(208, 240, 225);
}

.rune-block-wrapper:hover .rune-info {
  display: flex;
}

.rune-block-wrapper:hover .rune-info.left {
  display: none;
}

.rune-made {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: grey;
}

.rune-count,
.rune-gem {
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  color: #6a4343;
}

.rune-gem {
  color: black;
  white-space: nowrap;
}

.rune-recept-pic {
  list-style: none;
  padding: 0;
  display: flex;
  column-gap: 3px;
  margin-bottom: 0;
}

.rune-recept-pic img {
  height: 17px;
  width: 17px;
}

.rune-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
  margin-right: 10px;
}

.rune-info.left p {
  white-space: nowrap;
  margin-top: 3px;
  margin-bottom: 0px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.rune-name-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-image: url(./img/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: .9;

}


/*words*/
.runewords__wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;
}

.runeword-block-wrapper {
  position: relative;
  display: flex;
  z-index: 1;
}

.runeword__left {
  width: 160px;
  background: #EEEEEE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 12px 0;
  box-sizing: border-box;
}

.runeword__name-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.runeword__nameR {
  text-align: center;
  font-size: 17px;
  color: rgba(72, 73, 14, 0.79);
  font-weight: bold;

}

.runeword__nameE {
  color: rgba(83, 83, 83, 0.8976);
  font-weight: normal;
}

.runeword__name {
  margin-top: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.runeword__name-title {
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.38);
  margin-right: 5px;
}

.runeword__name-text {
  color: rgba(0, 0, 0, 0.5376);
  font-weight: bold;
  font-size: 14px;
}

.runeword__recept {
  display: flex;
  justify-content: center;
  column-gap: 5px;
  margin-top: 7px;
}

.runeword__recept-img {
  height: 22px;
  width: 22px;
}

.runeword__recept-name {
  color: rgba(0, 0, 0, 0.5376);
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  margin-top: 3px;
  text-align: center;
}

.runeword__equip {
  border-top: 1px solid rgba(48, 48, 48, 0.5);
  padding-left: 10px;
  padding-right: 10px;
}

.runeword__equip-header {
  margin-top: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

.runeword__equip-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2px;
  row-gap: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.runeword__equip-item {
  height: 18px;
  padding: 0 8px;
  font-size: 10px;
  font-weight: 500;
  color: white;
  background: rgba(48, 48, 48, 0.69);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
}

.runeword__level {
  margin-top: auto;
}

.runeword__level-title {
  font-size: 14px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 8px;
  margin-bottom: 3px;
  font-weight: bold;
  text-align: center;
}

.runeword__level-text {
  text-align: center;
  color: rgb(168 170 86);
  font-weight: bold;
  font-size: 16px;
}

.runeword__right {
  padding: 12px;
  background: #303030;
  text-align: center;
  width: 300px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.runeword__right-header {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  font-weight: bold;
}

.runeword__favorites {
  position: absolute;
  right: -7px;
  top: -7px;
  z-index: 3;

}

.runeword__settings {
  background-color: #EEEEEE;
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  max-width: 930px;
  width: 100%;
  margin: 20px auto 0px;
  box-sizing: border-box;

}

.runeword__settings .rune__settings-item {
  justify-content: center;
}

.rune__filter-buttons {
  display: flex;
  margin: 10px 0;
  column-gap: 15px;
}

.rune__filter-btn-ch {
  display: none;
}

.rune__filter-btn-label {
  background-color: #30303085;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 24%);
  border-radius: 2px;
  height: 25px;
  padding: 0 0px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  letter-spacing: 0.04em;
  width: 80px;

}

.rune__filter-btn-ch:checked+label {
  background-color: #303030;
  box-shadow: none;
  color: #ffffffcf;
}

.runeword__favorites-btn {
  width: 35px;
  height: 35px;
  background: #393939;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12), 0px 14px 14px rgba(0, 0, 0, 0.237602);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.runeword__favorites-btn:hover {
  opacity: .8;
}

.runeword__favorites img {
  width: 16px;
  height: 16px;
}

.runeword__favorites {}

.runeword__abilities-list {
  margin-top: 7px;
}

.runeword__abilities-item {
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.0357143em;

  color: #80CBC4;
}

.runeword__abilities-item-custom {
  color: rgba(255, 255, 255, 0.9);
  font-weight: bold;
  font-size: 13px;
  margin-top: 4px;
}

.runeword__abilities-item-custom:after {
  content: ":";
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.main__logo {
  width: 300px;
  height: 300px;
  /* animation: 40s linear 0s normal none infinite running rotate; */
}

.main__header {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 48px;
  text-transform: uppercase;
}

.main__links {
  display: grid;
  row-gap: 10px;
}

.button {
  background: #009688;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  height: 39px;
  padding: 0 16px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
}

.rune__button {
  background: #303030;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  height: 30px;
  padding: 0 16px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border: none;

}

.buttons {
  display: flex;
  column-gap: 10px;
}

.button:hover,
.rune__button:hover {
  background: linear-gradient(0deg, rgba(189, 189, 189, 0.3), rgba(189, 189, 189, 0.3)), #009688;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24);
}

.rune__instruction {
  background: #EEEEEE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 3px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  opacity: 0.7;
}

.rune__settings {
  display: flex;
  justify-content: space-between;
}

.rune__settings-item {
  color: #eeeeee;
  display: flex;
  margin: 10px 0;
  align-items: center;

}

.rune__checkbox {
  display: none;
}

.rune__checkbox-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #d4c53e;
}

.rune__settings-label {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #303030;
}

.rune__checkbox-label:after,
.rune__settings-label:after {
  content: "";
  height: 15px;
  width: 15px;
  background-color: #bec0c0;
  border-radius: 3px;
  margin-left: 5px;
  color: #313334db;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

}

input:checked+.rune__checkbox-label:after,
input:checked+.rune__settings-label:after {
  content: "✔";
}

@media(max-width:768px) {
  .content-wrapper {
    padding: 30px 10px;
  }

  .runeword-block-wrapper {
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .runeword__left {
    width: 100%;
  }

  .runeword__right {
    width: 100%;
  }

  .runeword__name {
    flex-direction: row;
    justify-content: center;
  }
}

@media(max-width:580px) {
  .main__logo {
    width: 250px;
    height: 250px;
  }

  .main__header {
    font-size: 27px;
  }

  .rune__settings {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media(max-width:710px) {
  .runeword__settings {
    flex-direction: column;
    max-width: 400px;
  }

  .rune__filter-buttons {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 7px;
  }

  .rune__filter-btn-label {
    width: 72px;
    font-size: 10px;
  }
}

.films {
  background-color: #a6c1a6;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 99px;
}

.films__list-header {
  text-align: left;
  font-weight: bold;
  color: #D5DDE5;
  background: #1b1e24;
  border-bottom: 4px solid #9ea7af;
  font-size: 23px;
  padding: 24px;
}

.films__list {
  width: 390px;
}

.film {
  width: 100%;
  padding: 6px 20px;
  background: white;
  border-bottom: 1px solid #C1C3D1;
  box-sizing: border-box;
  transition: .3s;
  display: grid;
  grid-template-columns: auto 40px;
  align-items: center;
}

.film:nth-child(odd) {
  background-color: #ebebeb;
}

.film.film_is-watched {
  background-color: #90bb90;
}

.film:hover {
  background-color: #4e5066;
}

.film:hover .film__btn {}

.film__btn {
  font-weight: 500;
  color: #313030;
  text-decoration: none;
  padding: 10px;
  border: 1px solid;
  margin: 10px;
  display: flex;
  transition: .3s;
  background-color: white;
}

.film_is-watched .film__btn {
  background-color: #badfba;
}

.film__icon {
  margin-right: 6px;
  width: 18px;
  height: 18px;
}

.film__btn:hover {
  background: #5c608a;
  color: wheat;
}

.films__main {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  background: beige;
  box-shadow: inset 0 1px 1px rgb(111 55 125 / 80%), inset 0 -1px 0px rgb(63 59 113 / 20%), 0 9px 16px 0 rgb(0 0 0 / 30%), 0 4px 3px 0 rgb(0 0 0 / 30%), 0 0 0 1px #150a1e;
  background-image: linear-gradient(#8365a5, #534167);
  text-shadow: 0 0 21px rgb(223 206 228 / 50%),
}

.film_watched {
  width: 40px;
  height: 40px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid grey;
}

.film_watched:hover .film__check-icon {
  width: 20px;
  height: 20px;
}

.film__check-icon {
  width: 15px;
  height: 15px;
  transition: .1s;
}

.films__buttons {
  display: flex;
}

.films__btn {
  width: 50%;
  height: 50px;
  background-color: #ebebeb;
  border: 1px solid grey;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 16px;
  color: rgb(72, 71, 71);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

}

.films__btn.active {
  background-color: #ff7300;
  color: rgb(43, 43, 43);
}

.films__btn_alena img {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.dragging:active {
  cursor: pointer;
}