.FifteensContainer__playGround {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 5px;
    padding-top: 10px;
}

.FifteensContainer__buttons {
    display: flex;
    column-gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px;
    justify-content: center;

}

.FifteenContainer__modal-btns {
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
    justify-content: flex-end;

}

.FifteensContainer__moves {
    color: #16b8f3;
    font-size: 22px;
}