.FifteenEl__container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2a1f62;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    user-select: none;
    border-radius: 8px;
    box-shadow: 10px 10px 30px 0 #1d0dca17;
    transition: .2s;
    background-color: #292929;
    color: white;
    border-radius: 8px;

}

.FifteenEl__container_no-drag {
    cursor: no-drop;
}

.FifteenEl__container_drag {
    cursor: move;
}

.FifteenEl__container_empty {
    width: 100%;
    height: 100%;
}